.resources-row {
  padding: 80px 0px;
  text-align: center;
  background-color: #131528;

  @media only screen and (min-width: 768px) {
    padding: 150px 0px;
  }
}

.resources-title {
  font: 30px Montserrat-Bold;
  color: white;

  @media only screen and (min-width: 768px) {
    font-size: 45px;
  }
}

.resources-description {
  font: 18px Montserrat-Medium;
  color: white;
  margin-top: 1.2em;

  @media only screen and (min-width: 768px) {
    font-size: 20px;
  }
}

.resources-row2 {
  padding: 40px 0px;

  @media only screen and (min-width: 768px) {
    padding: 80px 0px;
  }
}

.resources-card {
  position: relative;
  margin: 20px 0px;

  @media only screen and (min-width: 576px) {
    margin: 20px;
  }

  @media only screen and (min-width: 768px) {
    margin: 20px;
  }

  @media only screen and (min-width: 1200px) {
    margin: 40px;
  }
}

.resources-card:hover .card-overlay {
  opacity: 1;
}

.resources-card-img {
  width: 100%;
}

.card-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  background: #000000;
  transition: 0.3s ease;
  opacity: 0;
  vertical-align: middle;
  text-align: center;
}

.card-overlay-button,
.card-overlay-button:hover {
  position: relative;
  top: 42%;
  width: fit-content;
  height: fit-content;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  font: 20px Montserrat-SemiBold;
  color: #368973;
  border: 0px;
}

.more-button {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000016;
  border: 1px solid black;
  font: 20px Montserrat-Light;
  color: black;
  height: fit-content;
  padding: 20px 60px;
}

@primary-color: black;@modal-header-bg: #FBAF41;@modal-content-bg: #F5F5F5;@menu-bg: none;@menu-highlight-color: none;