.lesson-completed-container {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.lesson-completed-row {
  background-color: #0d1114;
  text-align: center;
  position: fixed;
  height: 100%;
  width: 100%;
  padding: 0rem;

  @media only screen and (min-width: 400px) {
    padding: 3rem;
  }
}

.lesson-completed-title {
  font: 25px Montserrat-SemiBold;
  color: white;
}

.lesson-completed-description {
  font: 18px Montserrat-SemiBold;
  color: white;
}

.lesson-completed-continue-button,
.lesson-completed-continue-button:focus,
.lesson-completed-continue-button:hover {
  font: 20px Montserrat-SemiBold;
  color: black;
  background-color: white;
  border: 2px solid black;
  border-radius: 10px;
  height: fit-content;
  padding: 20px 50px;
}

.lesson-completed-redo-text {
  font: 18px Montserrat-SemiBold;
  color: white;
  cursor: pointer;
}

@primary-color: black;@modal-header-bg: #FBAF41;@modal-content-bg: #F5F5F5;@menu-bg: none;@menu-highlight-color: none;