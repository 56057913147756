.topbar {
  margin: 10px 0px;

  @media only screen and (min-width: 992px) {
    margin: 25px 0px;
  }
}

.top-title {
  font: 30px Montserrat-Bold;
  color: black;
}

.top-login {
  font: 18px Montserrat-Medium;
  color: #0d111470;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 100%;
}

.divider {
  opacity: 0.14;
  background-color: #707070;
  height: 1px;
}

.rows {
  margin: 40px 0px 100px;
}

.row1-title {
  font: 26px Montserrat-Medium;
  color: black;
  display: inline-block;
  opacity: 0.4;
  margin-top: 20px;

  @media only screen and (min-width: 768px) {
    font-size: 22px;
    margin-top: 0px;
  }

  @media only screen and (min-width: 1200px) {
    font-size: 26px;
  }
}

.row1-title-2 {
  font: 20px Montserrat-Medium;
  color: black;
  display: inline-block;

  @media only screen and (min-width: 768px) {
    font-size: 18px;
  }

  @media only screen and (min-width: 1200px) {
    font-size: 20px;
  }
}

.row1-big-title {
  font: 25px Montserrat-Bold;
  color: black;
  margin-top: 20px;

  @media only screen and (min-width: 768px) {
    font-size: 40px;
    margin-top: 10px;
  }

  @media only screen and (min-width: 1200px) {
    font-size: 55px;
    margin-top: 20px;
  }
}

.row-big-title {
  font: 20px Montserrat-Bold;
  color: black;
  margin-top: 20px;

  @media only screen and (min-width: 768px) {
    font-size: 30px;
    margin-top: 10px;
  }

  @media only screen and (min-width: 1200px) {
    font-size: 45px;
    margin-top: 20px;
  }
}

.row1-description {
  width: 95%;
  font: 14px Montserrat-Medium;
  color: #00000080;
  line-height: 26px;
  margin-top: 1.2em;

  @media only screen and (min-width: 768px) {
    width: 60%;
    font-size: 14px;
    line-height: 26px;
    margin-top: 1.2em;
  }

  @media only screen and (min-width: 1200px) {
    font-size: 16px;
    line-height: 32px;
  }
}

.row1-img {
  width: 95%;

  @media only screen and (min-width: 992px) {
    margin-top: 40px;
  }
  @media only screen and (min-width: 1200px) {
    display: unset;
  }
}

.button-text-white {
  width: 200px;
  height: 60px;
  background-color: black;
  border: 3px solid black;
  border-radius: 15px;
  box-shadow: 0px 10px 30px #0d111430;
  font: 14px Montserrat-Bold;
  color: white;
  margin-top: 2.4em;

  @media only screen and (min-width: 768px) {
    margin-top: 1.2em;
  }

  @media only screen and (min-width: 1200px) {
    margin-top: 2.4em;
  }
}

.button-text-white:hover,
.button-text-white:focus {
  color: black;
  background: white;
  border-color: unset;
}

.row1-scroll-down {
  font: 16px Poppins-Medium;
  color: black;
  opacity: 0.5;
  display: inline-block;
  margin-right: 15px;
  visibility: hidden;

  @media only screen and (min-width: 1600px) {
    visibility: unset;
    margin-top: 0px;
  }
}

.mouse-icon {
  visibility: hidden;

  @media only screen and (min-width: 1600px) {
    visibility: visible;
  }
}

.row-title {
  font: 15px Montserrat-Medium;
  color: black;
  display: inline-block;

  @media only screen and (min-width: 768px) {
    font-size: 20px;
  }

  @media only screen and (min-width: 1200px) {
    font-size: 22px;
  }
}

.row-title-2 {
  font: 15px  Montserrat-Bold;
  color: black;
  display: inline-block;

  @media only screen and (min-width: 768px) {
    font-size: 20px;
  }

  @media only screen and (min-width: 1200px) {
    font-size: 22px;
  }
}

.row-title-D2 {
  font: 15px  Montserrat-Bold;
  color: black;
  display: inline-block;

  @media only screen and (min-width: 268px) {
    font-size: 15px;
    font-family: Montserrat-Regular;
    margin-top: 1rem;
  }

  @media only screen and (min-width: 768px) {
    font-size: 15px;
    font-family: Montserrat-Bold;
    margin-top: 0rem;
  }

  @media only screen and (min-width: 1200px) {
    font-size: 24px;
  }
}

.row2-title {
  font: 30px Poppins-Regular;
  color: black;
  display: inline-block;

  // @media only screen and (min-width: 768px) {
  //   width: 100%;
  // }

  // @media only screen and (min-width: 1200px) {
  //   width: unset;
  //   display: inline-block;
  // }
}

.row2-title-2 {
  font: 26px Poppins-Bold;
  color: black;
  display: inline-block;

  // @media only screen and (min-width: 325px) {
  //   margin-left: 6px;
  // }
  // @media only screen and (min-width: 768px) {
  //   margin-left: 0px;
  // }

  // @media only screen and (min-width: 1200px) {
  //   display: inline-block;
  //   margin-left: 6px;
  // }
}

.row3-big-title {
  font: 30px Montserrat-Bold;
  color: black;

  @media only screen and (min-width: 208px) {
    font-size: 20px;
  }

  @media only screen and (min-width: 768px) {
    font-size: 30px;
  }

  @media only screen and (min-width: 1200px) {
    font-size: 40px;
  }
}

.row2-big-title {
  font: 30px Montserrat-Bold;
  color: black;

  @media only screen and (min-width: 208px) {
    font-size: 18px;
  }

  @media only screen and (min-width: 768px) {
    font-size: 20px;
  }

  @media only screen and (min-width: 1200px) {
    font-size: 26px;
  }
}

.row2-points-segment {
  margin-top: 30px;

  @media only screen and (min-width: 1200px) {
    margin-top: 80px;
  }
}

.row2-sub-title {
  font: 18px Montserrat-Bold;
  color: black;

  @media only screen and (min-width: 768px) {
    font-size: 20px;
  }

  @media only screen and (min-width: 1200px) {
    font-size: 26px;
  }
}

.row2-sub-description {
  width: 100%;
  font: 14px Montserrat-Medium;
  color: #00000080;
  line-height: 32px;

  @media only screen and (min-width: 768px) {
    width: 75%;
    font-size: 14px;
    line-height: 24px;
  }

  @media only screen and (min-width: 1200px) {
    font-size: 16px;
    line-height: 32px;
  }
}

.row2-img {
  width: 100%;
  text-align: center;

  @media only screen and (min-width: 300px) {
    width: 80%;
  }

  @media only screen and (min-width: 500px) {
    width: 40%;
  }

  @media only screen and (min-width: 769px) {
    width: 80%;
  }

}

.row2-points-img {
  width: 80%;
  

  @media only screen and (min-width: 1200px) {
    width: 70%;
  }
}

.row3-description {
  font: 16px Montserrat-Medium;
  color: #00000080;
  line-height: 32px;
  @media only screen and (min-width: 320px) {
    margin-top: 10px;
  }
}

.our-service {
  font: 16px Montserrat-Medium;
  color: #00000080;
  line-height: 32px;
  border-radius: 10px;
  margin-top: "0px";

  @media only screen and (min-width: 320px) {
    margin-top: 10px;
  }
}

.our-service:hover {
  transform: scale(1.05);
}

.row3-card {
  border: 1px solid #d3d3d3;
  border-radius: 10px;
  padding: 30px;
  text-align: center;
}

.row3-card-img-layer {
  position: absolute;
  width: 100%;
  top: 10px;
  z-index: 1;
}

.row3-card-img-container {
  text-align: center;
  width: "0%";

  @media only screen and (min-width: 200px) {
    width: 100%;
  }

  @media only screen and (min-width: 768px) {
    width: 130%;
    display: inline-block;
  }
}

.row3-card-img {
  background-color: white;
  width: 45%;

  @media only screen and (min-width: 992px) {
    width: 35%;
  }

  @media only screen and (min-width: 1200px) {
    width: auto;
  }
}

.row3-card-title {
  font: 25px Montserrat-Bold;
  color: black;
  margin-top: 80px;

  @media only screen and (min-width: 576px) {
    font-size: 30px;
  }

  @media only screen and (min-width: 1200px) {
    font: 36px;
    margin-top: 120px;
  }
}

.row4-big-title {
  font: 25px Montserrat-Bold;
  color: black;

  @media only screen and (min-width: 768px) {
    font-size: 30px;
  }

  @media only screen and (min-width: 1200px) {
    font-size: 48px;
  }
}

.row4-description {
  font: 25px Montserrat-Regular;
  color: black;

  @media only screen and (min-width: 768px) {
    font-size: 20px;
  }

  @media only screen and (min-width: 1200px) {
    font-size: 25px;
  }
}

.row4-marquee-text {
  font: 14px Montserrat-SemiBold;
  color: black;
  display: inline-block;
  padding: 10px 20px;
  border: 2px solid black;
  border-radius: 50px;
  margin: 10px;

  @media only screen and (min-width: 768px) {
    font-size: 18px;
    margin: 15px;
    padding: 15px 30px;
  }

  @media only screen and (min-width: 1200px) {
    font-size: 20px;
    margin: 20px;
  }
}

// Marquee styling
.sc-gsTCUz.emKueO,
.sc-gsTCUz.bhdLno {
  height: unset !important;
}

.row5-big-title {
  font: 30px Montserrat-Bold;
  color: black;

  @media only screen and (min-width: 768px) {
    font-size: 30px;
  }

  @media only screen and (min-width: 1200px) {
    font-size: 48px;
  }
}

.row5-description {
  font: 16px Montserrat-Medium;
  color: #00000080;
  line-height: 32px;
}

.row5-card {
  padding: 40px 25px;
  border: 0px;
  border-radius: 50px;
  box-shadow: 0px 10px 30px #00000010;
  text-align: center;
  margin: 20px 10px;
  transform: scale(1);
  transition: 0.5s ease;

  .button-text-white {
    width: 160px;
    height: 60px;
    background-color: white;
    border-radius: 15px;
    border: 3px solid black;
    font: 14px Montserrat-Bold;
    color: black;
    margin-top: 2.4em;

    @media only screen and (min-width: 768px) {
      width: fit-content;
      height: 50px;
    }

    @media only screen and (min-width: 1200px) {
      width: 150px;
      height: 60px;
    }
  }

  @media only screen and (min-width: 768px) {
    padding: 40px 25px;
    margin: 20px 10px;
  }

  @media only screen and (min-width: 1200px) {
    margin: 80px 40px 40px;
    padding: 80px 50px;
  }
}

.row5-card:hover {
  transform: scale(1.1);
  transition: 0.5s ease;

  .button-text-white {
    background-color: black;
    color: white;
  }
}

.row5-card-img {
  width: 40%;

  @media only screen and (min-width: 768px) {
    width: 30%;
  }

  @media only screen and (min-width: 1200px) {
    width: auto;
  }
}

.row5-card-title {
  font: 26px Montserrat-Medium;
  color: black;
  margin: 30px 0px 20px;

  @media only screen and (min-width: 768px) {
    font-size: 22px;
  }

  @media only screen and (min-width: 1200px) {
    font-size: 26px;
  }
}

.row5-card-description {
  font: 18px Montserrat-Medium;
  color: #6b6b6b;
  margin-top: 1.2em;

  @media only screen and (min-width: 768px) {
    font-size: 14px;
  }

  @media only screen and (min-width: 1200px) {
    font-size: 18px;
  }
}

.row5-card-emphasis {
  font: 18px Montserrat-Medium;
  color: black;
  display: inline-block;

  @media only screen and (min-width: 768px) {
    font-size: 16px;
  }

  @media only screen and (min-width: 1200px) {
    font-size: 18px;
  }
}

.row5-card-price {
  font: 35px Montserrat-Medium;
  color: black;
  margin-top: 1.2em;

  @media only screen and (min-width: 768px) {
    font-size: 26px;
  }

  @media only screen and (min-width: 1200px) {
    font-size: 42px;
  }
}

.row5-card-price-sup {
  font: 20px Montserrat-Medium;
  color: black;
  vertical-align: top;
  top: -3px;

  @media only screen and (min-width: 768px) {
    font-size: 16px;
  }

  @media only screen and (min-width: 1200px) {
    font-size: 24px;
  }
}

.row5-card-price-sub {
  font: 20px Montserrat-Medium;
  color: black;

  @media only screen and (min-width: 768px) {
    font-size: 16px;
  }

  @media only screen and (min-width: 1200px) {
    font-size: 24px;
  }
}

.footer-black-bg {
  background-color: black;
  margin-top: 20px;

  @media only screen and (min-width: 576px) {
    margin-top: 0px;
    border-top-left-radius: 15px;
  }
}

.footer-title-col {
  margin: 0px;

  @media only screen and (min-width: 576px) {
    margin: 60px 0px;
  }
}

.footer-title {
  font: 30px Montserrat-Bold;
  color: black;

  @media only screen and (min-width: 768px) {
    font-size: 26px;
  }

  @media only screen and (min-width: 1200px) {
    font-size: 30px;
  }
}

.footer-title-2 {
  font: 26px Montserrat-Medium;
  color: black;
  display: inline-block;
  opacity: 0.4;
  margin-top: 20px;

  @media only screen and (min-width: 768px) {
    font-size: 22px;
    margin-top: 0px;
  }

  @media only screen and (min-width: 1200px) {
    font-size: 26px;
  }
}

.footer-title-3 {
  font: 20px Montserrat-Medium;
  color: black;
  display: inline-block;
  margin-left: 5px;

  @media only screen and (min-width: 768px) {
    font-size: 18px;
    margin-left: 0px;
  }

  @media only screen and (min-width: 1200px) {
    font-size: 20px;
    margin-left: 5px;
  }
}

.footer-icon {
  display: inline-block;
  
}

.footer-text {
  font: 14px Montserrat-Medium;
  color: white;
  display: inline-block;
  width: 80%;

  @media only screen and (min-width: 200px) {
    font-size: 11px;
  }

  @media only screen and (min-width: 360px) {
    width: 80%;
    font-size: 14px;
  }

  @media only screen and (min-width: 1200px) {
    width: 100%;
    font-size: 14px;
  }
}

.footer-text-2 {
  font: 16px Montserrat-Medium;
  color: #caced0;
  opacity: 0.3;
  margin: 10px 20px;

  @media only screen and (min-width: 576px) {
    margin: 0px;
  }
}

.footer-columns {
  margin-top: 20px;

  @media only screen and (min-width: 768px) {
    margin-top: 20px;
  }

  @media only screen and (min-width: 1200px) {
    margin-top: 60px;
  }
}

.ant-comment-avatar img {
  width: auto;
  height: 28px;
  border-radius: unset;
}

.landing-page-modal-body {
  height: 100vh;

  .ant-modal-content {
    border-radius: 0px;
  }
}

@primary-color: black;@modal-header-bg: #FBAF41;@modal-content-bg: #F5F5F5;@menu-bg: none;@menu-highlight-color: none;