.choose-lesson-banner {
  width: 100%;
}

.choose-lesson-row {
  background-color: #f8f8f8;
  text-align: center;
  padding-bottom: 1rem;
}

.choose-lesson-title {
  font: 24px Montserrat-Bold;
  color: black;
  @media only screen and (min-width: 576px) {
    font-size: 30px;
  }


}

.choose-lesson-table {
  margin-top: 3.6em;

  .table-header {
    text-align: start;
    font: 25px Montserrat-Bold;
    color: black;
    padding: 5px 20px;
    font-size: 20px;
    @media only screen and (min-width: 576px) {
      font-size: 30px;
    }
  }
  
  .table-row-text {
    font: 17px OpenSans-Regular;
    color: black;
    padding: 0px 0px 0px 10px;
    font-size: 15px;
    @media only screen and (min-width: 576px) {
      font-size: 20px;
      padding: 20px 20px;
    }
  }
  
  .ant-spin-nested-loading {
    box-shadow: 0px 6px 6px #00000016;
    border-radius: 10px !important;
  }

  .ant-table,
  .ant-table-bordered {
    border-radius: 10px !important;
  }

  .ant-table-title {
    background-color: #e1e1e1;
    border-radius: 10px 10px 0px 0px;
  }

  .ant-table-footer {
    background-color: white;
    border-radius: 0px 0px 10px 10px;
  }
}

@primary-color: black;@modal-header-bg: #FBAF41;@modal-content-bg: #F5F5F5;@menu-bg: none;@menu-highlight-color: none;