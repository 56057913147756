.login-body {
  padding: 50px 0px;
  background-color: white;

  @media only screen and (min-width: 992px) {
    min-height: 100vh;
  }

  @media only screen and (min-width: 1025px) {
    min-height: 95vh;
  }
}

.login-header {
  font: 28px Poppins-SemiBold;
  color: black;
}

.login-description {
  font: 14px Montserrat-Light;
  color: #212526;
}

.login-input {
  font: 14px Montserrat-Light;
  height: 50px;
  border-radius: 5px;
  border-color: #b1b1b1;
  padding: 0px 30px;

  .ant-input {
    font: 14px Montserrat-Light !important;
  }
}

.login-button {
  font: 14px Montserrat-Bold;
  color: white;
  background-color: black;
  border: 3px solid black;
  border-radius: 15px;
  width: 100%;
  height: 50px;
}

.login-button:focus,
.login-button:hover {
  color: black;
  background-color: white;
  border-color: unset;
}

.login-align {
  // display: flex;
  // align-items: center;
}

@primary-color: black;@modal-header-bg: #FBAF41;@modal-content-bg: #F5F5F5;@menu-bg: none;@menu-highlight-color: none;