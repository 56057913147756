.email-banner {
  width: 100%;
}

.email-row1 {
  background-color: #f8f8f8;
  padding: 40px 0px;
  text-align: center;

  @media only screen and (min-width: 768px) {
    padding: 70px 0px;
  }
}

.email-title {
  font: 24px Montserrat-Bold;
  color: black;
  @media only screen and (min-width: 576px) {
    font-size: 30x;
  }
}

.email-description {
  font: 14px Montserrat-Medium;
  color: black;
  line-height: 30px;
  margin-top: 2.4em;
  @media only screen and (min-width: 576px) {
    font-size: 18px;
  }
}

.email-search-input, 
.email-search-input:hover {
  width: 90%;
  border-radius: 12px;
  padding: 10px 30px;
  box-shadow: 0px 13px 25px #d0d0d080;
  font: 18px Montserrat-Medium;
  color: black;
  margin-top: 2.4em;

  @media only screen and (min-width: 768px) {
    width: 50%;
  }

  @media only screen and (min-width: 992px) {
    width: 40%;
  }
}

.accordion-category {
  border-radius: 5px;
  border: 1px solid #70707050;
  border-bottom: 0px;
}

.accordion-title {
  font: 23px Montserrat-Bold;
  color: black;
  padding: 20px;
  text-align: center;

  @media only screen and (min-width: 992px) {
    font-size: 28px;
  }
}

.accordian {
  border: 0px;
  border-radius: 5px;
  background-color: white;
}

.email-template-description {
  font: 15px Montserrat-Medium;
  color: black;
  line-height: 30px;

  @media only screen and (min-width: 992px) {
    font-size: 17px;
  }
}
@primary-color: black;@modal-header-bg: #FBAF41;@modal-content-bg: #F5F5F5;@menu-bg: none;@menu-highlight-color: none;