.home-rows {
  margin: 30px 0px;

  @media only screen and (min-width: 768px) {
    margin: 50px 0px;
  }
}

.home-title {
  font: 30px Montserrat-Bold;
  color: black;
}

.home-date {
  font: 18px Montserrat-Medium;
  color: black;
  margin-top: 2.4rem;
}

.home-title2 {
  font: 20px Montserrat-Bold ;
  color: black;
  margin-top: 2.4rem;
  @media only screen and (min-width: 768px) {
    font-size: 23px !important;
  }
}

.home-title2::after {
  width: 0% !important;
}

.home-quote-title {
  font: 23px Montserrat-Medium;
  color: black;
  margin-top: 1.2rem;
  @media only screen and (min-width: 768px) {
    font-size: 28px;
  }
}

.home-quote-text {
  font: 16px Montserrat-Regular;
  color: black;
  margin-top: 2.4rem;
  @media only screen and (min-width: 768px) {
    font-size: 20px;
  }
}

.home-row2-title {
  font: 23px Montserrat-Bold !important;
  color: black;
}

.home-category-title {
  font: 30px Montserrat-Bold;
  color: black;
  margin-top: 1.2rem;
}

.home-category-row {
  margin-top: 1.2em !important;
  margin-bottom: 60px !important;
}

.home-card-img {
  width: 100%;
  cursor: pointer;
  margin-top: 1.2rem;

  @media only screen and (min-width: 768px) {
    margin-top: 0px;
  }
}

.home-card-title {
  font: 15px Montserrat-Bold;
  color: #707070;

  @media only screen and (min-width: 768px) {
    font-size: 12px !important;
  }

  @media only screen and (min-width: 992px) {
    font-size: 15px;
  }
}

.home-card-title2 {
  font: 16px Montserrat-Bold !important;
  color: black;

  @media only screen and (min-width: 576px) {
    font-size: 14px !important;
    margin-top: 0.6rem;
  }

  @media only screen and (min-width: 768px) {
    font-size: 16px !important;
    margin-top: 0.6rem;
  }

  @media only screen and (min-width: 992px) {
    font-size: 22px !important;
  }

  @media only screen and (min-width: 1200px) {
    font-size: 25px;
    margin-top: 1.2rem;
  }
}

.home-card {
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000016;
  padding: 20px !important;
  cursor: pointer;

  @media only screen and (min-width: 768px) {
    padding: 10px;
  }

  @media only screen and (min-width: 992px) {
    padding: 30px !important;
  }
}

.home-card-half {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@primary-color: black;@modal-header-bg: #FBAF41;@modal-content-bg: #F5F5F5;@menu-bg: none;@menu-highlight-color: none;