.probing-questions-row {
  padding: 70px 0px;
  text-align: center;
}

.probing-questions-title {
  font: 28px Montserrat-Bold;
  color: black;

  @media only screen and (min-width: 768px) {
    font-size: 30px;
  }
}

.probing-questions-description {
  font: 16px Montserrat-Medium;
  color: black;
  line-height: 30px;
  margin-top: 2.4em;

  @media only screen and (min-width: 768px) {
    font-size: 18px;
  }
}

.probing-questions-search-input,
.probing-questions-search-input:hover {
  width: 100%;
  height: 60px;
  border-radius: 10px;
  padding: 10px 30px;
  box-shadow: 0px 13px 25px #d0d0d080;
  font: 20px Montserrat-Light;
  color: black;
  margin-top: 2.4em;

  @media only screen and (min-width: 768px) {
    width: 80%;
  }

  @media only screen and (min-width: 1200px) {
    width: 60%;
  }
}

.probing-questions-row2 {
  padding: 0px 0px 80px;
  text-align: center;
}

.filter-button,
.filter-button:hover {
  font: 14px Montserrat-Medium;
  color: black;
  border-radius: 30px;
  border: unset;
  box-shadow: unset;
  margin: 0px 5px;

  @media only screen and (min-width: 768px) {
    font-size: 18px;
    margin: 10px 5px;
  }
}

.filter-button:focus {
  background-color: #368973;
  border-radius: 30px;
  color: unset;
}

.probing-questions-divider {
  margin: 60px 0px;
  opacity: 1;
  background-color: #70707016;
}

.probing-questions-card {
  position: relative;
}

.probing-questions-card:hover .card-overlay {
  opacity: 1;
}

.probing-questions-card-img {
  width: 100%;
}

.card-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  background: #000000;
  transition: 0.3s ease;
  opacity: 0;
  vertical-align: middle;
  text-align: center;
}

.card-overlay-button,
.card-overlay-button:hover {
  position: relative;
  top: 42%;
  width: fit-content;
  height: fit-content;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  font: 20px Montserrat-SemiBold;
  color: #368973;
  border: 0px;

  @media only screen and (min-width: 992px) {
    top: 40% !important;
  }

  @media only screen and (min-width: 1200px) {
    top: 42%;
  }
}

.more-button {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000016;
  border: 1px solid black;
  font: 20px Montserrat-Light;
  color: black;
  height: fit-content;
  padding: 10px 40px !important;

  @media only screen and (min-width: 768px) {
    padding: 20px 60px;
  }
}

@primary-color: black;@modal-header-bg: #FBAF41;@modal-content-bg: #F5F5F5;@menu-bg: none;@menu-highlight-color: none;