.profile-title{
    font: 25px Montserrat-Bold;
    line-height: normal;

    @media only screen and (min-width: 768px) {
        font-size: 30px;
      }
    
      @media only screen and (min-width: 992px) {
        font-size: 35px;
      }
}

.profile-body {
    font: 14px Montserrat-Regular;
    text-align: center;
    color: black;
  
    @media only screen and (min-width: 768px) {
      font-size: 16px;
    }
  
    @media only screen and (min-width: 992px) {
      font-size: 18px;
    }
  }


  .profile-button{
    border-radius: 24px;
    padding: 12px;
    height: auto;
    font: 23px Montserrat-Medium;
    color: white;
    border: none;

    @media only screen and (min-width: 200px) {
      font-size: 13px;
    }
  
    @media only screen and (min-width: 450px) {
      font-size: 21px;
    }

    @media only screen and (min-width: 768px) {
      font-size: 16px;
    }
  
    @media only screen and (min-width: 916px) {
      font-size: 21px;
    }
  }
  
@primary-color: black;@modal-header-bg: #FBAF41;@modal-content-bg: #F5F5F5;@menu-bg: none;@menu-highlight-color: none;