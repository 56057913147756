.exam-banner {
  width: 100%;
}

.exam-row2 {
  background-color: #f8f8f8;
  padding: 80px 0px;
}

.exam-description {
  font: 18px Montserrat-SemiBold;
  color: black;
  text-align: center;
  @media only screen and (min-width: 576px) {
    font-size: 24px;
  }
  @media only screen and (min-width: 700px) {
    font-size: 28px;
  }
}

.exam-card {
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000016;
  padding: 40px !important;
}

.exam-img {
  width: auto;
}

.exam-level-text {
  font: 10px Montserrat-Bold;
  color: #a2a2a2;
  position: absolute;
  left: 44%;
  bottom: 32%;
}

.exam-level-best-seller {
  font: 25px Montserrat-Bold;
  color: black;
  position: absolute;
  left: 39%;
  bottom:41%;
}

.exam-level-text-passed {
  font: 10px Montserrat-Bold;
  color: black;
  position: absolute;
  left: 44%;
  bottom: 32%;
}

.exam-score-text {
  font: 20px Montserrat-Medium;
  color: black;
}

.exam-card-half {
  text-align: center;
}

.exam-start-button,
.exam-start-button:focus,
.exam-start-button:hover {
  font: 18px Montserrat-SemiBold;
  color: white;
  border-radius: 10px;
  background-color: black;
  border: 2px solid black;
  height: fit-content;
  width: 160px;
  padding: 18px 0px;
}

.exam-start-button-small,
.exam-start-button-small:focus,
.exam-start-button-small:hover {
  font: 15px Montserrat-SemiBold;
  color: white;
  border-radius: 10px;
  background-color: black;
  border: 2px solid black;
  height: fit-content;
  width: 130px;
  padding: 18px 0px;
}

@primary-color: black;@modal-header-bg: #FBAF41;@modal-content-bg: #F5F5F5;@menu-bg: none;@menu-highlight-color: none;